<script src="../transfer/transfer.component.ts"></script>
<div id="bg"></div>
<div id="wrapper" *ngIf="transfer">
  <header id="header">
    <app-navbar> </app-navbar>
    <div class="content" style="padding-top: 5%;">
      <div [ngClass]="{'inner': showMyClass}">
        <h2>{{transfer.name}}</h2>
        <h3 >Transfer Details</h3>

        <nav>
          <li ><strong>Duration</strong>: {{transfer.duration}}</li>
          <li ><strong>Meeting Point</strong>: {{transfer.meetingPoint}}</li>
        </nav>
      </div>
    </div>
  </header>
  <br>

  <div id="mainMulti" @fadeIn *ngIf="showMyClass" class="d-flex align-items-center justify-content-center" style="padding-top: 2%;">
    <p>{{transfer.description}}</p>
    <div id="carousel-container" class="d-flex align-items-center justify-content-center">
      <div id="my-slider" class="carousel slide" data-ride="carousel">
        <!-- Indicators -->
        <ol class="carousel-indicators">
          <li data-target="#my-slider" data-slide-to="0" class="active"></li>
          <li data-target="#my-slider" data-slide-to="1"></li>
          <li data-target="#my-slider" data-slide-to="2"></li>
          <li data-target="#my-slider" data-slide-to="3"></li>
          <li data-target="#my-slider" data-slide-to="4"></li>
          <li data-target="#my-slider" data-slide-to="5"></li>
          <li data-target="#my-slider" data-slide-to="6"></li>
          <li data-target="#my-slider" data-slide-to="7"></li>
          <li data-target="#my-slider" data-slide-to="8"></li>
          <li data-target="#my-slider" data-slide-to="9"></li>
        </ol>

        <!-- Wrapper for slides -->
        <div class="carousel-inner" role="listbox">
          <div class="carousel-item active">
            <img src="{{transfer.imagePath+'/image01.jpg'}}" class="d-block w-100 h-100" alt="Internet Services"/>
          </div>
          <div class="carousel-item" *ngFor="let image of images">
            <img src="{{transfer.imagePath+image}}" class="d-block w-100 h-100" alt="Home Phone Services"/>
          </div>
        </div>
        <!-- Controls -->
        <a class="carousel-control-prev" href="#my-slider" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#my-slider" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
  <br>
  <h1 class="contactUs_margin">Contact Us</h1>
  <form method="post" action="#">
    <mat-form-field>
      <mat-label style="color: white">Choose a date</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="date">

      <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
      <mat-datepicker touchUi="true" #picker></mat-datepicker>
    </mat-form-field>
    <div class="fields">
      <div class="field half">
        <label for="name">Name</label>
        <input type="text" name="name" id="name" [(ngModel)]="name"/>
      </div>
      <div class="field half">
        <label for="name">Last Name</label>
        <input type="text" name="last name" id="last name" [(ngModel)]="lastName"/>
      </div>
      <div class="field half">
        <label for="email">Email</label>
        <input type="text" name="email" id="email" [(ngModel)]="email"/>
      </div>
      <div class="field half">
        <label for="email">Phone Number</label>
        <input type="text" name="phone" id="phone" [(ngModel)]="phone"/>
      </div>
      <div class="field">
        <label for="message">Message</label>
        <textarea name="message" id="message" rows="4" [(ngModel)]="message"></textarea>
      </div>
    </div>
    <ul class="actions">
      <li><input type="submit" (click)="sendEmail(transfer.name)" value="Send Message" class="primary" /></li>
      <li><input type="reset" value="Reset" /></li>
    </ul>
    <div *ngIf="isPopupOpen" class="popup-overlay">
      <div class="popup-content">
        <h2>Email inviata.</h2>
        <p>Sarai ricontattato entro 24h</p>
        <button style="margin-left: 25%;" (click)="close()">OK</button>
      </div>
    </div>
    <ul class="actions">
      <li><a target="_blank" href="//api.whatsapp.com/send?phone=393337428672&text=Hi MADE IN WORLD AGENCY, I would like some infos">
        <div>
          <fa-icon [icon]="faWhatsapp" size="2x"></fa-icon>
        </div>
      </a></li>
      <li><a target="_blank" href="mailto:marikacostagliola91@gmail.com?subject=Made In World Info Request">
        <div>
          <fa-icon [icon]="faEnvelope" size="2x"></fa-icon>
        </div>
      </a></li>
      <li>
        <a target="_blank" href="http://m.me/420876478334736">
          <div>
            <fa-icon [icon]="faFacebookMessenger" size="2x"></fa-icon>
          </div>
        </a>
      </li>
    </ul>
  </form>
</div>


