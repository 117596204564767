import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {ApiService} from "../api.service";
import {Meta, Title} from "@angular/platform-browser";
import { faFacebookF, faInstagram, faWhatsapp,  } from '@fortawesome/free-brands-svg-icons';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {
  showMyClass : boolean;
  name:string;
  lastName:string;
  email:string;
  phone:string;
  message:string;
  faFacebook = faFacebookF;
  faInstagram = faInstagram;
  faWhatsapp = faWhatsapp;


  constructor(private router: Router,
              private apiService : ApiService,private metaTagService: Meta, private titleService: Title) {
    this.showMyClass = false;
    setTimeout(() => {
      this.showMyClass = true;
    }, 200);

    this.titleService.setTitle('Made in World Contact');
    this.metaTagService.addTags([
      { name: 'description', content: 'Contact to receive infos about Tour, Transfer and Service in Italy' },
      { name: 'author', content: 'm2label'},
      { name: 'keywords', content: 'Angular,Contacts,Information, Infos, Tour, Transfer, Italy, Service, Homepage, Made in World Agency, Made in World, Agency'},
      { property: 'og:title', content: 'Made in World Contact' },
      { property: 'og:description', content: 'Contact to receive infos about Tour, Transfer and Service in Italy' },
      { property: 'twitter:title', content: 'Made in World Contact' },
      { property: 'twitter:description', content: 'Contact to receive infos about Tour, Transfer and Service in Italy' },
    ]);
  }

  ngOnInit() {
  }

  sendEmail(subject:string){
    console.log("sending email")
    const body = {
      "firstName": this.name,
      "lastName": this.lastName,
      "email": this.email,
      "tourName": subject,
      "phoneNumber": this.phone,
      "notes": this.message
    };

    this.apiService.sendContactEmail(body);
    this.showPopup()
    this.clearInput();
  }
  isPopupOpen = false;

  showPopup() {
    this.isPopupOpen = true;
  }
  close(){
    this.isPopupOpen = false;
  }
  clearInput(){
    this.name = "";
    this.lastName = "";
    this.email = "";
    this.phone = "";
    this.message = "";
  }

  back(){

    this.router.navigate(['home']);
  }

}
