import {Component, OnInit} from '@angular/core';
import {animate, query, stagger, style, transition, trigger} from "@angular/animations";
import {ActivatedRoute} from "@angular/router";
import {Services} from "../model/services";
import {OndemandExperienceService} from "../ondemand-experience.service";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate('1s ease-in', style({ opacity: 1 })),
      ])

    ]),
    trigger('pageAnimations', [
      transition(':enter', [
        query('.tour', [
          style({opacity: 0, transform: 'translateY(-100px)'}),
          stagger(30, [
            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)',
              style({ opacity: 1, transform: 'none' }))
          ])
        ])
      ])
    ]),
  ]
})
export class ServicesComponent implements OnInit{

  showMyClass : boolean;

  services : Services[] = [];

  constructor(private onDemandExperienceService: OndemandExperienceService, private activatedRoute: ActivatedRoute,
              private metaTagService: Meta, private titleService: Title){
  this.titleService.setTitle('Made in World Services');
  this.metaTagService.addTags([
      { name: 'description', content: 'Services list of Made in World Agency' },
      { name: 'author', content: 'm2label'},
      { name: 'keywords', content: 'Angular, Italy, Services, Homepage, Made in World Agency, Made in World, Agency'},
      { property: 'og:title', content: 'Made in World Services' },
      { property: 'og:description', content: 'Services list of Made in World Agency' },
      { property: 'twitter:title', content: 'Made in World Services' }
    ]);
    this.showMyClass = false;
    this.activatedRoute.paramMap.subscribe(params => {

      setTimeout(() => {

        this.showMyClass = true;

      }, 200);
    });

    console.log("navigate")


  }
  ngOnInit(): void {
    this.getServices();
  }

  getServices() : void {
    this.onDemandExperienceService.getServices().subscribe(services =>{
      this.services  = services
    });
  }

}
