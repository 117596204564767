<!-- Wrapper -->
<div id="wrapper">

    <div class="logo d-flex align-items-center justify-content-center" id="logo">
        <img src="assets/logo_white.png" alt="">
    </div>
    <br>
    <!-- Header -->
    <header id="header">
        <div class="content">
            <div [ngClass]="{'inner': showMyClass}">
                <p id="pfirst">"Happiness is</p>
                <p id="psecond">a journey</p>
                <p id="pthird"> not a destination"</p>
            </div>
        </div>
        <app-navbar></app-navbar>
        <nav>
            <!--Destination-->
            <h2 style="text-align: center;">DESTINATION</h2>
            <ul *ngIf="destinations">
                <li style="padding-bottom: 20%; padding-top: 10%;">
                    <a routerLink="/home/destinations/{{destinations[0].id}}" routerLinkActive="active">
                        <img src="../assets/images/destinations/italy2.png" alt="Card image cap"
                             style="width: 5rem; height: 5rem;">
                        <h5 class="card-title">{{destinations[0].name}}</h5>
                    </a>
                </li>
                <li style="padding-bottom: 20%; padding-top: 10%;">
                    <a routerLink="/home/destinations/{{destinations[1].id}}" routerLinkActive="active">
                        <img src="../assets/images/destinations/europe5.png" alt="Card image cap"
                             style="width: 5rem; height: 5rem;">
                        <h5 class="card-title">{{destinations[1].name}}</h5>
                    </a>
                </li>
                <li style="padding-bottom: 20%; padding-top: 10%;">
                    <a routerLink="/home/destinations/{{destinations[2].id}}" routerLinkActive="active">
                        <img src="../assets/images/destinations/world2.png" alt="Card image cap"
                             style="width: 5rem; height: 5rem;">
                        <h5 class="card-title">{{destinations[2].name}}</h5>
                    </a>
                </li>
            </ul>
        </nav>
    </header>

    <!-- Main -->
    <div id="main" style="padding-top: 5%;">

        <!-- The most popular tour -->
        <h2 class="major">BEST SELLER</h2>
        <div class="row row-cols-1 row-cols-md-3 g-4" style="padding-bottom: 5%;">
            <div *ngIf="tour" class="col-md-4 mt-4">
                <a routerLink="/home/tour/{{tour.id}}" routerLinkActive="active">
                    <div class="card profile-card-5">
                        <div class="card-img-block">
                            <img class="card-img-top" src="{{tour.imagesPath+'/image01.jpg'}}" alt="Card image cap">
                        </div>
                        <div class="card-body pt-0">
                            <h5 class="card-title">{{tour.name}}</h5>
                            <p class="card-text">{{tour.name}}</p>

                        </div>
                    </div>
                </a>
            </div>
            <div *ngIf="transfer" class="col-md-4 mt-4">
                <a routerLink="/home/transfer/{{transfer.id}}" routerLinkActive="active">
                    <div class="card profile-card-5">
                        <div class="card-img-block">
                            <img class="card-img-top" src="{{transfer.imagePath+'/image01.jpg'}}" alt="Card image cap">
                        </div>
                        <div class="card-body pt-0">
                            <h5 class="card-title">{{transfer.name}}</h5>
                            <p class="card-text">{{transfer.name}}</p>

                        </div>
                    </div>
                </a>
            </div>
            <div *ngIf="service" class="col-md-4 mt-4">
                <a routerLink="/home/service/{{service.id}}" routerLinkActive="active">
                    <div class="card profile-card-5">
                        <div class="card-img-block">
                            <img class="card-img-top" src="{{service.imagePath+'/image01.jpg'}}" alt="Card image cap">
                        </div>
                        <div class="card-body pt-0">
                            <h5 class="card-title">{{service.title}}</h5>
                            <p class="card-text">{{service.description}}</p>

                        </div>
                    </div>
                </a>
            </div>
        </div>

        <!-- About -->
        <h2 id="about" class="major">ABOUT</h2>

        <p style="font-size: 1.5rem;font-family:'Lobster', sans-serif;">'The world is a book<br> and those who do not travel read only one page'</p>
        <p style="font-size: medium;padding-bottom: 5%;">The MADE IN WORLD Agency is a company based in Naples founded
            in 2018. Its main
            activity is focused on logistics planning and the organization of services, events, assistance and
            everything
            that is an integral part of national and international tourism
            In its main activity of Organization and Tour Operating, it operates in Italy and abroad and enjoys
            experience in
            the
            development of any initiative related to entertainment and comfort, both for individual travelers, groups,
            business
            class and VIP class... </p>


        <div id="TA_cdsratingsonlynarrow458" class="TA_cdsratingsonlynarrow">
            <ul id="NJQkL7N4m01" class="TA_links FkP7T1LOesc">
                <li id="e50icXS" class="leQGLbUN2YX">
                    <a target="_blank"
                       href="https://www.tripadvisor.it/Attraction_Review-g187785-d15515117-Reviews-MADE_IN_WORLD_Agency-Naples_Province_of_Naples_Campania.html"></a>
                </li>
            </ul>
        </div>
    </div>


    <!-- Footer -->
    <app-footer></app-footer>
</div>

<!-- BG -->
<div id="bg"></div>






