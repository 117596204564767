import { Component } from '@angular/core';
import {animate, query, stagger, style, transition, trigger} from "@angular/animations";
import {ActivatedRoute} from "@angular/router";
import {TransferService} from "../transfer.service";
import {Transfer} from "../model/transfer";
import {DestinationsComponent} from "../destinations/destinations.component";
import {DestinationService} from "../destination.service";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-transfers',
  templateUrl: './transfers.component.html',
  styleUrls: ['./transfers.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate('1s ease-in', style({ opacity: 1 })),
      ])

    ]),
    trigger('pageAnimations', [
      transition(':enter', [
        query('.tour', [
          style({opacity: 0, transform: 'translateY(-100px)'}),
          stagger(30, [
            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)',
              style({ opacity: 1, transform: 'none' }))
          ])
        ])
      ])
    ]),
  ]
})
export class TransfersComponent {
  showMyClass : boolean;
  transfers : Transfer[];

  constructor(private transferService: TransferService, private activatedRoute: ActivatedRoute,
              private metaTagService: Meta, private titleService: Title){
    this.titleService.setTitle('Made in World Transfers');
    this.metaTagService.addTags([
      { name: 'description', content: 'Transfer list of Made in World Agency' },
      { name: 'author', content: 'm2label'},
      { name: 'keywords', content: 'Angular, Italy, Transfers, Destinations, Homepage, Made in World Agency, Made in World, Agency'},
      { property: 'og:title', content: 'Made in World Transfers' },
      { property: 'og:description', content: 'Transfer list of Made in World Agency' },
      { property: 'twitter:title', content: 'Made in World Transfers' }
    ]);
    this.showMyClass = false;
    this.activatedRoute.paramMap.subscribe(params => {

      setTimeout(() => {

        this.showMyClass = true;

      }, 200);
    });

    console.log("navigate")
  }

  getTransfers() : void {
    const id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    this.transferService.getTransfersByDestinationId(id).subscribe(transfers =>{
      this.transfers  = transfers
    });
  }

  ngOnInit(): void {

    this.getTransfers();
  }
}
