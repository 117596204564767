
<div id="bg"></div>
<div id="wrapper">
  <header id="header">
    <app-navbar> </app-navbar>
    <div class="content">
      <div [ngClass]="{'inner': showMyClass}">
        <h1>Contact</h1>

      </div>
    </div>

    <p class="h2" >Made in World</p>
    <p >Via Alessandro Volta, 17<br>80024 – Cardito<br> +39 388.826.52.91
      <br> madeinworldagency@gmail.com<br> www.madeinworldagency.com</p>
    <ul class="icons">
      <li><a href="https://www.facebook.com/madeinworldagency" role="button">
            <fa-icon [icon]="faFacebook"></fa-icon>
          </a>
      </li>
      <li>
        <a href="https://www.instagram.com/madeinworldagency/" role="button">
          <fa-icon [icon]="faInstagram"></fa-icon>
        </a>
      </li>
      <li><a href="//api.whatsapp.com/send?phone=393337428672&text=Hi MADE IN WORLD AGENCY, I would like some infos">
            <fa-icon [icon]="faWhatsapp"></fa-icon>
          </a>
      </li>
      <li>
        <a href="mailto:marikacostagliola91@gmail.com?subject=Made In World Info Request">
          <i class="bi bi-envelope"></i>
        </a>
      </li>
    </ul>
  </header>

<div id="main">
<!-- Form to send Email -->
  <br>
  <br>
  <form method="post" action="#">
    <div class="fields">
      <div   class="field half">
        <label for="name">Name</label>
        <input type="text" name="name" id="name" [(ngModel)]="name"/>
      </div>
      <div class="field half">
        <label for="name">Last Name</label>
        <input type="text" name="last name" id="last name" [(ngModel)]="lastName"/>
      </div>
      <div class="field half">
        <label for="email">Email</label>
        <input type="text" name="email" id="email" [(ngModel)]="email"/>
      </div>
      <div class="field half">
        <label for="email">Phone Number</label>
        <input type="text" name="phone" id="phone" [(ngModel)]="phone"/>
      </div>
      <div class="field">
        <label for="message">Message</label>
        <textarea name="message" id="message" rows="4" [(ngModel)]="message"></textarea>
      </div>
    </div>
    <ul class="actions">
      <li><input type="submit" (click)="sendEmail('CONTACT REQUEST')" value="Send Message" class="primary" /></li>
      <li><input type="reset" value="Reset" /></li>
    </ul>
    <div *ngIf="isPopupOpen" class="popup-overlay">
      <div class="popup-content">
        <h2>Email inviata.</h2>
        <p>Sarai ricontattato entro 24h</p>
        <button style="margin-left: 25%;" (click)="close()">OK</button>
      </div>
    </div>
  </form>

</div>
  <app-footer></app-footer>
</div>


