import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {ApiService} from "../api.service";
import {OndemandExperienceService} from "../ondemand-experience.service";
import {Services} from "../model/services";
import {animate, query, stagger, style, transition, trigger} from "@angular/animations";
import {faFacebookMessenger, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {Meta, Title} from "@angular/platform-browser";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-events',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate('1s ease-in', style({ opacity: 1 })),
      ])

    ]),
    trigger('pageAnimations', [
      transition(':enter', [
        query('.tour', [
          style({opacity: 0, transform: 'translateY(-100px)'}),
          stagger(30, [
            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)',
              style({ opacity: 1, transform: 'none' }))
          ])
        ])
      ])
    ]),
  ]
})
export class ServiceComponent implements OnInit{

  showMyClass : boolean;

  name:string;
  lastName:string;
  email:string;
  phone:string;
  message:string;
  date :any=new Date();
  formatDate : string;

  service : Services | undefined;

  constructor(
    private onDemandExperienceService: OndemandExperienceService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService, private metaTagService: Meta, private titleService: Title){
    this.titleService.setTitle('Made in World Service');
    this.metaTagService.addTags([
      { name: 'description', content: 'Navbar of Made in World Agency' },
      { name: 'author', content: 'm2label'},
      { name: 'keywords', content: 'Angular, Italy, Service, Homepage, Made in World Agency, Made in World, Agency'},
      { property: 'og:title', content: 'Made in World Service' },
      { property: 'og:description', content: 'Navbar of Made in World Agency' },
      { property: 'twitter:title', content: 'Made in World Service' }
    ]);

    this.showMyClass = false;
    setTimeout(() => {
      this.showMyClass = true;
    }, 200);

  }

  sendEmailTo(subject:string){
    console.log("sending email")
    this.formatDate = this.date.getDate()+'/'+(this.date.getMonth()+1)+'/'+this.date.getFullYear()
    const body = {
      "firstName": this.name,
      "lastName": this.lastName,
      "email": this.email,
      "tourName": subject,
      "phoneNumber": this.phone,
      "date": this.formatDate,
      "notes": this.message
    };

    this.apiService.sendRequestEmail(body);
    this.showPopup();
    this.clearInput();
  }
  isPopupOpen = false;

  showPopup() {
    this.isPopupOpen = true;
  }
  close(){
    this.isPopupOpen = false;
  }
  clearInput(){
    this.name = "";
    this.lastName = "";
    this.email = "";
    this.phone = "";
    this.message = "";
  }
  back(){

    this.router.navigate(['home']);
  }

  ngOnInit(): void {
    this.getService();
  }

  getService(): void {
    const id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    this.onDemandExperienceService.getService(id)
      .subscribe(service => this.service = service);
  }

    protected readonly faFacebookMessenger = faFacebookMessenger;
  protected readonly faEnvelope = faEnvelope;
  protected readonly faWhatsapp = faWhatsapp;
}
